import ClientSideNavigation from '@app/components/ClientSideNavigation';
import SelectTherapistPopover from '@app/components/SelectTherapistPopover';
import { useClientSize } from '@app/hooks';
import { BreakpointsEnum } from '@app/types';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { SidebarLayout } from '../SidebarLayout';

interface Props {
  children: ReactNode;
}

const ClientLayout: FC<Props> = ({ children }) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <SidebarLayout
      isSticky={!isMobile}
      sideComponent={
        <Root>
          <ClientSideNavigation />
          <StyledSelectTherapistPopover />
        </Root>
      }
    >
      {children}
    </SidebarLayout>
  );
};

export default ClientLayout;

export const getClientLayout = (page: ReactNode) => {
  return <ClientLayout>{page}</ClientLayout>;
};

const Root = styled.div`
  display: flex;
  height: 100%;

  @media (min-width: ${BreakpointsEnum.sm}px) {
    flex-direction: column;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    flex-direction: column-reverse;
    margin-bottom: 32px;
  }
`;

const StyledSelectTherapistPopover = styled(SelectTherapistPopover)`
  @media (min-width: ${BreakpointsEnum.sm}px) {
    margin-top: auto;
  }
  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-bottom: 36px;
  }
`;
