import { FC } from 'react';
import styled from 'styled-components';
import ButtonLink from '../ButtonLink';

interface Props {
  signUpLink: string;
}

const SignInButton: FC<Props> = ({ signUpLink }) => {
  return (
    <ButtonWrapper>
      <StyledButtonLink href={signUpLink}>Вход</StyledButtonLink>
    </ButtonWrapper>
  );
};

export default SignInButton;

const StyledButtonLink = styled(ButtonLink)`
  padding: 9px 20px;
  border: 1px solid #3DAE9F;
  background-color: transparent;
  color: #3DAE9F;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  transition: all 0.3s ease;
  max-height: 40px;
  margin: auto;

  &:hover {
    color: #FFF;
    background-color: #3DAE9F;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;
