import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Arrow } from '@app/ui/iconComponents';
import Container from '@app/components/Container';
import FlatBtn from '@app/ui/components/FlatBtn';
import { BreakpointsEnum } from '@app/types';
import { MainLayout } from '../MainLayout';

interface Props {
  children?: ReactNode;
}

const BackLayout: FC<Props> = ({ children }) => {
  const router = useRouter();

  return (
    <MainLayout>
      <StyledContainer>
        <StyledBreadcrumbsContainer>
          <StyledFlatBtn
            onClick={() => router.back()}
            icon={<Arrow width={16} height={16} />}
          >
            Назад
          </StyledFlatBtn>
        </StyledBreadcrumbsContainer>
        {children}
      </StyledContainer>
    </MainLayout>
  );
};

export const getBackLayout = (page: ReactNode) => {
  return <BackLayout>{page}</BackLayout>;
};

export default BackLayout;

const StyledBreadcrumbsContainer = styled.div`
  margin-bottom: 40px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-bottom: 18px;
  }
`;

const StyledFlatBtn = styled(FlatBtn)`
  padding: 0 5px;

  div {
    margin-right: 4px;
  }

  svg {
    transform: rotate(180deg);
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 60px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding-top: 24px;
  }
`;
