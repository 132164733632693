import React, { FC, ReactNode } from 'react';
import { BaseLayout } from '../BaseLayout';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return <BaseLayout hasAccess>{children}</BaseLayout>;
};

export default MainLayout;

export const getMainLayout = (page: ReactNode) => {
  return <MainLayout>{page}</MainLayout>;
};
