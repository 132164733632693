import { COLORS, TYPOGRAPHY } from '@app/constants';
import { BreakpointsEnum } from '@app/types';
import Banner from '@app/ui/components/Banner';
import { InfoCircle2 } from '@app/ui/iconComponents';
import { FC } from 'react';
import styled from 'styled-components';
import TypedLink from '../TypedLink';

interface Props {
  isRequiredCard?: boolean;
  isRequiredVideo?: boolean;
}

const ConnectCardBanner: FC<Props> = ({ isRequiredCard, isRequiredVideo }) => {
  const text =
    isRequiredCard && isRequiredVideo
      ? 'Внимание! Привяжите карту и загрузите видео чтобы начать проводить консультации.'
      : isRequiredCard
      ? 'Внимание! Привяжите карту чтобы начать проводить консультации.'
      : isRequiredVideo
      ? 'Внимание! Загрузите видео чтобы начать проводить консультации.'
      : '';

  return (
    <Banner
      icon={<InfoCircle2 color={COLORS.grayscale600} width={24} height={24} />}
      text={text}
      rightComponent={
        isRequiredCard && (
          <TypedLink to="/therapist/payments">
            <Button>Привязать карту</Button>
          </TypedLink>
        )
      }
    />
  );
};

export default ConnectCardBanner;

const Button = styled.a`
  display: inline-block;
  background-color: transparent;
  border: 1px solid ${COLORS.grayscale600};
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  color: ${COLORS.grayscale600};
  ${TYPOGRAPHY.body1Medium14}
  text-align: center;

  @media (min-width: ${BreakpointsEnum.sm}px) {
    max-width: 200px;
  }
`;
