import { CreateRecommendedFilterRequestDto } from '@app/api';

export enum StorageKeys {
  UTM_CAMPAIGN = 'pogovorim_utm_campaign',
  UTM_MEDIUM = 'pogovorim_utm_medium',
  UTM_SOURCE = 'pogovorim_utm_source',
  INTERVIEW = 'pogovorim_client_interview',
  PRODUCT_TOUR = 'pogovorim_product_tour_completed',
  REDIRECT_URL = 'pogovorim_redirect_url',
}

export const getProductTourFromStorage = (): string => {
  const jsonItemFromLocaleStorage = localStorage.getItem(
    `${StorageKeys.PRODUCT_TOUR}`,
  );
  return jsonItemFromLocaleStorage && JSON.parse(jsonItemFromLocaleStorage);
};

export const setProductTourToStorage = (payload: boolean) => {
  return localStorage.setItem(
    `${StorageKeys.PRODUCT_TOUR}`,
    JSON.stringify(payload),
  );
};

export const getCampaignFromStorage = (): string => {
  const jsonItemFromLocaleStorage = localStorage.getItem(
    `${StorageKeys.UTM_CAMPAIGN}`,
  );
  return jsonItemFromLocaleStorage && JSON.parse(jsonItemFromLocaleStorage);
};

export const getMediumFromStorage = (): string => {
  const jsonItemFromLocaleStorage = localStorage.getItem(
    `${StorageKeys.UTM_MEDIUM}`,
  );
  return jsonItemFromLocaleStorage && JSON.parse(jsonItemFromLocaleStorage);
};

export const getSourceFromStorage = (): string => {
  const jsonItemFromLocaleStorage = localStorage.getItem(
    `${StorageKeys.UTM_SOURCE}`,
  );
  return jsonItemFromLocaleStorage && JSON.parse(jsonItemFromLocaleStorage);
};

export const setCampaignToStorage = (payload: string) => {
  return localStorage.setItem(
    `${StorageKeys.UTM_CAMPAIGN}`,
    JSON.stringify(payload),
  );
};

export const setMediumToStorage = (payload: string) => {
  return localStorage.setItem(
    `${StorageKeys.UTM_MEDIUM}`,
    JSON.stringify(payload),
  );
};

export const setSourceToStorage = (payload: string) => {
  return localStorage.setItem(
    `${StorageKeys.UTM_SOURCE}`,
    JSON.stringify(payload),
  );
};

export const resetCampaignStorage = () => {
  return localStorage.removeItem(`${StorageKeys.UTM_CAMPAIGN}`);
};

export const resetMediumStorage = () => {
  return localStorage.removeItem(`${StorageKeys.UTM_MEDIUM}`);
};

export const resetSourceStorage = () => {
  return localStorage.removeItem(`${StorageKeys.UTM_SOURCE}`);
};

export const getInterviewFromStorage =
  (): CreateRecommendedFilterRequestDto => {
    const jsonItemFromLocaleStorage = localStorage.getItem(
      `${StorageKeys.INTERVIEW}`,
    );
    return jsonItemFromLocaleStorage && JSON.parse(jsonItemFromLocaleStorage);
  };

export const setInterviewToStorage = (
  payload: CreateRecommendedFilterRequestDto,
) => {
  return localStorage.setItem(
    `${StorageKeys.INTERVIEW}`,
    JSON.stringify(payload),
  );
};

export const resetInterviewStorage = () => {
  return localStorage.removeItem(`${StorageKeys.INTERVIEW}`);
};

export const setRedirectUrlAfterOnboarding = (redirectUrl: string) => {
  return localStorage.setItem(
    StorageKeys.REDIRECT_URL,
    JSON.stringify({ redirectUrl }),
  );
};

export const getRedirectUrlAfterOnboarding = (): string | undefined => {
  const jsonRedirectUrl = localStorage.getItem(StorageKeys.REDIRECT_URL);
  return jsonRedirectUrl && JSON.parse(jsonRedirectUrl).redirectUrl;
};

export const clearRedirectUrlAfterOnboarding = (): void => {
  localStorage.removeItem(StorageKeys.REDIRECT_URL);
};
