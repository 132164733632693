import { UserWithMetadataResponseDto } from '@app/api';
import { isInterviewDone } from '@app/helpers/isInterviewDone';

import ButtonLink from '@app/ui/components/ButtonLink';

import { isEmptyObject } from '@app/utils/isEmptyObject';
import styled from 'styled-components';
import TypedLink from '../TypedLink';
import { getInterviewFromStorage } from '@app/services/storageService';

export const getTitleByUser = ({
  user,
  metadata,
}: UserWithMetadataResponseDto) => {
  const interview = getInterviewFromStorage() ? getInterviewFromStorage() : {};
  const hasInterviewDoneLocalStorage = !isEmptyObject(interview);

  const title =
    isInterviewDone({ userRole: user.role, metadata }) ||
    hasInterviewDoneLocalStorage
      ? 'Пройти опрос еще раз?'
      : 'Подбор специалиста';

  return title;
};

export const getFooterByUser = ({
  user,
  metadata,
}: UserWithMetadataResponseDto) => {
  const interview = getInterviewFromStorage() ? getInterviewFromStorage() : {};
  const hasInterviewDoneLocalStorage = !isEmptyObject(interview);

  if (
    isInterviewDone({ userRole: user.role, metadata }) ||
    hasInterviewDoneLocalStorage
  ) {
    return (
      <TypedLink to="/client/interview">
        <StyledButtonLink>Пройти опрос</StyledButtonLink>
      </TypedLink>
    );
  } else
    return (
      <TypedLink to="/client/interview">
        <StyledButtonLink>Подобрать специалиста</StyledButtonLink>
      </TypedLink>
    );
};

const StyledButtonLink = styled(ButtonLink)`
  width: 100%;
`;

export const getTextByUser = ({
  user,
  metadata,
}: UserWithMetadataResponseDto) => {
  const interview = getInterviewFromStorage() ? getInterviewFromStorage() : {};
  const hasInterviewDoneLocalStorage = !isEmptyObject(interview);

  if (
    isInterviewDone({ userRole: user.role, metadata }) ||
    hasInterviewDoneLocalStorage
  )
    return 'Чтобы обновить список подходящих специалистов, пройдите опрос снова';
  else
    return 'Мы зададим вам несколько вопросов, которые помогут определиться с специалистом';
};
