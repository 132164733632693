import TherapistProfileSideNavigation from '@app/components/TherapistProfileSideNavigation';
import { COLORS } from '@app/constants';
import { useClientSize } from '@app/hooks';
import FlatBtn from '@app/ui/components/FlatBtn';
import { Arrow } from '@app/ui/iconComponents';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { SidebarLayout } from '../SidebarLayout';

interface Props {
  children: ReactNode;
}

const TherapistProfileLayout: FC<Props> = ({ children }) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');
  const { back } = useRouter();

  const backBtn = (
    <StyledFlatBtn onClick={back} icon={<Arrow width={7} height={12} />}>
      Назад
    </StyledFlatBtn>
  );

  return (
    <SidebarLayout
      isSticky={!isMobile}
      sideComponent={
        <>
          {isMobile && backBtn}
          <TherapistProfileSideNavigation />
        </>
      }
    >
      <div>
        {!isMobile && backBtn}
        {children}
      </div>
    </SidebarLayout>
  );
};

export default TherapistProfileLayout;

export const getTherapistProfileLayout = (page: ReactNode) => {
  return <TherapistProfileLayout>{page}</TherapistProfileLayout>;
};

const StyledFlatBtn = styled(FlatBtn)`
  padding-right: 5px;
  padding-left: 0;
  margin-bottom: 8px;
  color: ${COLORS.grayscale400};

  div {
    margin-right: 8px;
  }

  svg {
    transform: rotate(180deg);
  }
`;
