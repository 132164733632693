export interface Banner {
  title: string;
  description: string;
}

const banners: Banner[] = [
  {
    title: 'Лучшие психологи',
    description:
      'Высокая квалификация специалистов, прошедших самые строгие критерии отбора в&nbsp;индустрии',
  },
  {
    title: '5*',
    description: 'Средняя оценка качества сессий от&nbsp;пользователей сервиса',
  },
  {
    title: 'Служба заботы',
    description:
      'Ваш личный помощник всегда на&nbsp;связи для решения любого вопроса',
  },
  {
    title: 'Любые запросы',
    description:
      'В&nbsp;нашем сервисе вы&nbsp;найдёте психологов для личной, парной и&nbsp;детской терапии',
  },
  {
    title: 'Безопасность',
    description:
      'Мы&nbsp;гарантируем 100% конфиденциальность сессий и&nbsp;возможностью анонимного общения',
  },
];

export default banners;
