import { getLayout } from '@app/layouts';
import { LayoutEnum } from '@app/types';
import { NextPageContext } from 'next';
import { BaseContext, NextComponentType } from 'next/dist/shared/lib/utils';

export const withLayout =
  <C extends BaseContext = NextPageContext, IP = {}, P = {}>(
    layout: LayoutEnum,
    layoutProps?: any,
  ) =>
  (Component: NextComponentType<C, IP, P>) => {
    const ComponentWithLayout = (props: P) =>
      getLayout(layout, layoutProps)(<Component {...props} />);

    if (Component.getInitialProps) {
      ComponentWithLayout.getInitialProps = Component.getInitialProps;
    }

    return ComponentWithLayout;
  };
