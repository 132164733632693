import { UserResponseDtoRoleEnum } from '@app/api';
import { RoutesPath } from '@app/routers';

export const getHomepagePathByRole = (
  role?: UserResponseDtoRoleEnum,
): RoutesPath => {
  switch (role) {
    case UserResponseDtoRoleEnum.Patient:
      return '/client/consultations';
    case UserResponseDtoRoleEnum.Therapist:
      return '/therapist/consultations';
    case UserResponseDtoRoleEnum.Company:
      return '/for-business';
    default:
      return '/login';
  }
};
