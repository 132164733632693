import { COLORS } from '@app/constants';
import { FC } from 'react';
import styled from 'styled-components';
import Headroom from 'react-headroom';
import Container from '../Container';
import TypedLink from '../TypedLink';
import { getHomepagePathByRole } from '@app/helpers/getHomepagePathByRole';
import { BreakpointsEnum } from '@app/types';
import LogoMedsiXPogovorim from '@app/ui/iconComponents/LogoMedsiXPogovorim';

const MedsiHeader: FC = () => {
  return (
    <StyledHeadroom>
      <Root>
        <StyledContainer>
          <Nav>
            <LogoContainer>
              <TypedLink to={getHomepagePathByRole()}>
                <LogoMedsiXPogovorim width={240} height={30} />
              </TypedLink>
            </LogoContainer>
          </Nav>
        </StyledContainer>
      </Root>
    </StyledHeadroom>
  );
};

export default MedsiHeader;

interface StyleProps {
  $isAuthorized?: boolean;
  $hasAccess?: boolean;
  $hasBoxShadow?: boolean;
}

const StyledHeadroom = styled(Headroom)`
  position: relative;
  z-index: 100;
`;

const Root = styled.header<StyleProps>`
  position: relative;
  background-color: ${COLORS.grayscaleWhite};
  width: 100%;
  min-height: 60px;
  height: min-content;
  display: flex;
  box-shadow: 0 8px 12px 2px rgba(94, 112, 148, 0.04);
`;

const StyledContainer = styled(Container)<StyleProps>`
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 150px;
  width: 100%;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding: 0;
  }
`;

const Nav = styled.nav<StyleProps>`
  display: flex;
  width: 100%;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    width: max-content;
    margin: auto;
  }
`;

const LogoContainer = styled.div``;
