import { UserResponseDtoRoleEnum } from '@app/api';
import { useMemo } from 'react';

export const useSignUpLink = (role?: UserResponseDtoRoleEnum) => {
  return useMemo(() => {
    switch (role) {
      case UserResponseDtoRoleEnum.Therapist:
        return '/sign-up/therapist';
      case UserResponseDtoRoleEnum.Company:
        return '/login/business';
      case UserResponseDtoRoleEnum.Patient:
      default:
        return '/sign-up/client';
    }
  }, [role]);
};
