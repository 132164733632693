import TherapistSideNavigation from '@app/components/TherapistSideNavigation';
import { useClientSize } from '@app/hooks';
import { FC, ReactNode } from 'react';
import { SidebarLayout } from '../SidebarLayout';

interface Props {
  children: ReactNode;
}

const TherapistLayout: FC<Props> = ({ children }) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <SidebarLayout
      isSticky={!isMobile}
      sideComponent={<TherapistSideNavigation />}
    >
      {children}
    </SidebarLayout>
  );
};

export default TherapistLayout;

export const getTherapistLayout = (page: ReactNode) => {
  return <TherapistLayout>{page}</TherapistLayout>;
};
