import BusinessSideNavigation from '@app/components/BusinessSideNavigation';
import { useClientSize } from '@app/hooks';
import { FC, ReactNode } from 'react';
import { SidebarLayout } from '../SidebarLayout';

interface Props {
  children: ReactNode;
}

const BusinessLayout: FC<Props> = ({ children }) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <SidebarLayout
      isSticky={!isMobile}
      sideComponent={<BusinessSideNavigation />}
    >
      {children}
    </SidebarLayout>
  );
};

export default BusinessLayout;

export const getBusinessLayout = (page: ReactNode) => {
  return <BusinessLayout>{page}</BusinessLayout>;
};
