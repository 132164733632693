import { SideNavigationItem } from '@app/types';

export const SIDE_NAVIGATION_LINKS = {
  client: [
    {
      to: '/client/consultations',
      title: 'Мои консультации',
    },
    {
      to: '/client/history',
      title: 'История консультаций',
    },
    {
      to: '/client/therapists',
      title: 'Мои специалисты',
    },
    {
      to: '/client/faq',
      title: 'FAQ',
    },
    { title: 'Проверить оборудование', to: '/test-call' },
  ] as SideNavigationItem[],
  therapist: [
    {
      to: '/therapist/consultations',
      title: 'Консультации',
    },
    {
      to: '/therapist/history',
      title: 'История консультаций',
    },
    {
      to: '/therapist/schedule',
      title: 'Расписание',
    },
    // TODO: добавить ссылку
    // {
    //   to: '/',
    //   title: 'Мои супервизоры',
    // },
    {
      to: '/therapist/clients',
      title: 'Клиенты',
      exact: false,
    },
    {
      to: '/therapist/faq',
      title: 'FAQ',
    },
    { title: 'Проверить оборудование', to: '/test-call' },
  ] as SideNavigationItem[],
  business: [
    {
      to: '/for-business/settings',
      title: 'Мои данные',
    },
    {
      to: '/for-business',
      title: 'Промокоды',
    },
  ] as SideNavigationItem[],
  therapistProfile: [
    {
      to: '/therapist/profile',
      title: 'Профиль',
    },
    {
      to: '/therapist/settings',
      title: 'Данные аккаунта',
    },
    {
      to: '/therapist/payments',
      title: 'Платежи',
    },
  ] as SideNavigationItem[],
  clientProfile: [
    { title: 'Мои данные', to: '/client/profile' },
    { title: 'Мои платежи', to: '/client/payments' },
  ] as SideNavigationItem[],
};
