import { UserMetadataResponseDto, UserResponseDtoRoleEnum } from '@app/api';
import { useMemo } from 'react';
import { LOGOTYPES } from './logotypes';

type Params = {
  role?: UserResponseDtoRoleEnum;
  integration?: UserMetadataResponseDto['integration'];
};

export const useLogo = (param: Params) => {
  return useMemo(() => {
    if (param.integration === 'medsi') {
      return LOGOTYPES.medsi;
    }

    switch (param.role) {
      case UserResponseDtoRoleEnum.Therapist:
        return LOGOTYPES.therapist;
      case UserResponseDtoRoleEnum.Patient:
        return LOGOTYPES.client;
      case UserResponseDtoRoleEnum.Company:
        return LOGOTYPES.business;
      default:
        return LOGOTYPES.all;
    }
  }, [param.role, param.integration]);
};
