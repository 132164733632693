import ClientProfileSideNavigation from '@app/components/ClientProfileSideNavigation';
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { useClientSize } from '@app/hooks';
import { BreakpointsEnum } from '@app/types';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { SidebarLayout } from '../SidebarLayout';

interface Props {
  children: ReactNode;
}

const ClientProfileLayout: FC<Props> = ({ children }) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <SidebarLayout
      hasAside={!isMobile}
      sideComponent={
        <Root>
          <StyledClientProfileSideNavigation />
        </Root>
      }
    >
      {isMobile && (
        <Root>
          <StyledClientProfileSideNavigation />
        </Root>
      )}
      {children}
    </SidebarLayout>
  );
};

export default ClientProfileLayout;

export const getClientProfileLayout = (page: ReactNode) => {
  return <ClientProfileLayout>{page}</ClientProfileLayout>;
};

const Root = styled.section`
  width: 100%;
  max-width: 260px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
`;

const StyledClientProfileSideNavigation = styled(ClientProfileSideNavigation)`
  @media (max-width: ${BreakpointsEnum.sm}px) {
    max-width: 100%;
    padding: 0;
    background: none;
    ul {
      display: flex;
      column-gap: 2px;
    }

    li {
      &:not(:first-child) {
        margin-top: 0;
      }
    }

    li > a {
      ${TYPOGRAPHY.body1Medium14}
      padding: 10px;
      border-radius: 8px;
      margin-top: 0;

      &[data-current='true'] {
        background: ${COLORS.grayscale0};
      }
    }
  }
`;
