import { COLORS } from '@app/constants';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { BreakpointsEnum } from '@app/types';
import { ArrowDown } from '@app/ui/iconComponents';
import { useClientSize } from '@app/hooks/';
import { PopoverBackgroung } from '@app/ui/iconComponents';

interface Props {
  children?: ReactNode | ReactNode[];
  className?: string;
  onClick?: () => void;
  hasArrowButton?: boolean;
}

const Popover: FC<Props> = ({
  children,
  className,
  hasArrowButton = false,
  ...props
}) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <Root className={className} {...props}>
      {isMobile && (
        <BackgroundContainer>
          <PopoverBackgroung />
        </BackgroundContainer>
      )}

      {hasArrowButton && (
        <PopoverButton>
          <StyledArrowDown />
        </PopoverButton>
      )}

      {children}
    </Root>
  );
};

export default Popover;

const Root = styled.div`
  position: relative;
  width: fit-content;
  background-color: ${COLORS.grayscaleWhite};
  box-shadow: 0 8px 32px ${COLORS.shadowDark};
  border-radius: 24px;
  padding: 16px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding: 24px 16px;
  }
`;

const PopoverButton = styled.div`
  position: absolute;
  top: 26px;
  right: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${BreakpointsEnum.sm}px) {
    display: none;
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  transform: rotate(270deg);
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  svg {
    width: 139px;
    height: 121px;
  }
`;
