import { LayoutEnum } from '@app/types';
import { ReactNode } from 'react';
import { getTherapistLayout } from './TherapistLayout';
import { getClientLayout } from './ClientLayout';
import { getMainLayout } from './MainLayout';
import { getAuthLayout } from './AuthLayout';
import { getClientProfileLayout } from './ClientProfileLayout';
import { getTherapistProfileLayout } from './TherapistProfileLayout';
import { getBusinessLayout } from './BusinessLayout';
import { getBackLayout } from './BackLayout';
import { getMedsiLayout } from './MedsiLayout';
import { getBackLayoutV2 } from './BackLayoutV2';

export const getLayout = (layout: LayoutEnum, props?: any) => {
  return (page: ReactNode) => {
    switch (layout) {
      case LayoutEnum.Therapist:
        return getTherapistLayout(page);
      case LayoutEnum.Client:
        return getClientLayout(page);
      case LayoutEnum.Main:
        return getMainLayout(page);
      case LayoutEnum.Auth:
        return getAuthLayout(page, props);
      case LayoutEnum.ClientProfile:
        return getClientProfileLayout(page);
      case LayoutEnum.TherapistProfile:
        return getTherapistProfileLayout(page);
      case LayoutEnum.Business:
        return getBusinessLayout(page);
      case LayoutEnum.Back:
        return getBackLayout(page);
      case LayoutEnum.BackV2:
        return getBackLayoutV2(page);
      case LayoutEnum.Medsi:
        return getMedsiLayout(page);
    }
  };
};
