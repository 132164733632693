import Container from '@app/components/Container';
import { BreakpointsEnum } from '@app/types';
import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { BaseLayout } from '../BaseLayout';

interface Props {
  sideComponent?: ReactNode | ReactNode[];
  children?: ReactNode;
  isSticky?: boolean;
  hasAside?: boolean;
}

const SidebarLayout: FC<Props> = ({
  sideComponent,
  children,
  isSticky = false,
  hasAside = true,
}) => {
  return (
    <BaseLayout hasAccess>
      <StyledContainer $hasAside={hasAside}>
        {hasAside && (
          <StyledAside $isSticky={isSticky}>{sideComponent}</StyledAside>
        )}
        {children}
      </StyledContainer>
    </BaseLayout>
  );
};

export default SidebarLayout;

const StyledAside = styled.aside<{ $isSticky: boolean }>`
  margin-right: 40px;
  flex: 0 1 260px;
  height: calc(100vh - 156px);

  ${({ $isSticky }) =>
    $isSticky &&
    css`
      position: sticky;
      top: 80px;
    `}

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-right: 0;
    height: min-content;
    max-width: calc(100% - 32px);
  }
`;

const StyledContainer = styled(Container)<{ $hasAside: boolean }>`
  display: grid;
  grid-template-columns: ${({ $hasAside }) =>
    $hasAside ? '300px 1fr' : '1fr'};
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding-top: 16px;
    grid-template-columns: 1fr;
  }
`;
