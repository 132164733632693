import { UserResponseDtoRoleEnum } from '@app/api';

import React, { FC, ReactNode } from 'react';
import { BaseLayout } from '../BaseLayout';

interface AuthLayoutProps {
  children?: ReactNode;
  role?: UserResponseDtoRoleEnum;
  hasHeaderBoxShadow?: boolean;
}

const AuthLayout: FC<AuthLayoutProps> = ({
  children,
  role,
  hasHeaderBoxShadow = true,
}) => {
  return (
    <>
      <BaseLayout
        role={role}
        hasAccess={false}
        hasHeaderBoxShadow={hasHeaderBoxShadow}
      >
        {children}
      </BaseLayout>
    </>
  );
};

export default AuthLayout;

export const getAuthLayout = (page: ReactNode, props?: AuthLayoutProps) => {
  return <AuthLayout {...props}>{page}</AuthLayout>;
};
