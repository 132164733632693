import { SIDE_NAVIGATION_LINKS } from '@app/constants/sideNavigationLinks';
import { FC } from 'react';
import SideNavigation from '../SideNavigation';

interface Props {
  className?: string;
}

const ClientProfileSideNavigation: FC<Props> = (props) => {
  return (
    <SideNavigation {...props} data={SIDE_NAVIGATION_LINKS.clientProfile} />
  );
};

export default ClientProfileSideNavigation;
