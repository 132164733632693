import { UserResponseDtoRoleEnum } from '@app/api';
import { MedsiHeader } from '@app/components/Header';

import React, { FC, ReactNode } from 'react';

interface AuthLayoutProps {
  children?: ReactNode;
  role?: UserResponseDtoRoleEnum;
  hasHeaderBoxShadow?: boolean;
}

const MedsiLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <>
      <MedsiHeader />
      <main>{children}</main>
    </>
  );
};

export default MedsiLayout;

export const getMedsiLayout = (page: ReactNode, props?: AuthLayoutProps) => {
  return <MedsiLayout {...props}>{page}</MedsiLayout>;
};
