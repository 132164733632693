import Container from '@app/components/Container';
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { BreakpointsEnum } from '@app/types';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  icon?: ReactNode;
  text?: ReactNode;
  className?: string;
  rightComponent?: ReactNode;
}

const Banner: FC<Props> = ({ icon, text, className, rightComponent }) => {
  const hasRightComponent = !!rightComponent;

  return (
    <Root role="alert" className={className}>
      <StyledContainer>
        <Content $hasRightComponent={hasRightComponent}>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {text && <Text>{text}</Text>}
        </Content>
        {rightComponent}
      </StyledContainer>
    </Root>
  );
};

export default Banner;

const Root = styled.div`
  width: 100%;
  background-color: ${COLORS.systemWarning};
  color: ${COLORS.grayscale600};
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Text = styled.div``;

const Content = styled.div<{ $hasRightComponent: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    ${Text} {
      ${({ $hasRightComponent }) =>
        $hasRightComponent &&
        css`
          margin-bottom: 16px;
        `};
    }
  }

  ${Text} {
    ${({ $hasRightComponent }) =>
      $hasRightComponent &&
      css`
        margin-right: 20px;
      `};
    ${TYPOGRAPHY.headline2Medium16}
  }
`;

const IconWrapper = styled.span`
  @media (min-width: ${BreakpointsEnum.sm}px) {
    margin-right: 20px;
  }
  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-right: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
