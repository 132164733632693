import { WhatsApp } from '@app/ui/iconComponents';
import { FC } from 'react';
import styled, { CSSProp } from 'styled-components';
import IconBtn from '../IconBtn';

interface Props {
  buttonCSS?: CSSProp;
}

const WhatsAppBtn: FC<Props> = ({ buttonCSS }) => {
  return (
    <ButtonWrapper>
      <StyledIconBtn type="button" $CSS={buttonCSS}>
        <a href="https://wa.me/+79198707755" target="_blank" rel="noreferrer">
          <WhatsApp width={16} height={16} />
        </a>
      </StyledIconBtn>
    </ButtonWrapper>
  );
};

type DefaultCSSProp = { $CSS?: CSSProp };

const StyledIconBtn = styled(IconBtn)<DefaultCSSProp>`
  ${({ $CSS }) => $CSS}
  margin: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

export default WhatsAppBtn;
