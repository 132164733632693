import { UserResponseDtoRoleEnum } from '@app/api';
// import Footer from '@app/components/Footer';
import {Footer } from '@landing/components';
import Header from '@app/components/Header';
import { useRouter } from 'next/router';
import React, { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import banners from '@app/pageComponents/AllTherapistsPage/lib/banners';
import { useClientSize } from '@app/hooks';
import dynamic from 'next/dynamic';

const Carousel = dynamic(() => import('@app/ui/components/Carousel'), {
  ssr: false,
});

interface Props {
  children?: ReactNode;
  role?: UserResponseDtoRoleEnum;
  hasAccess?: boolean;
  hasHeaderBoxShadow?: boolean;
}

const BaseLayout: FC<Props> = ({
  children,
  role,
  hasAccess = false,
  hasHeaderBoxShadow = true,
}) => {
  const router = useRouter();
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const isTherapistPage = useMemo(() => {
    return router.pathname === '/therapist/[url]';
  }, [router]);

  const isAllTherapistPage = useMemo(() => {
    return ( 
      router.pathname.includes('/all-therapists')
      ||
      router.pathname.includes('/child-therapists')
    )
  }, [router]);

  function footerRole( initialRole: UserResponseDtoRoleEnum | 'client' | 'therapist' | 'business' | undefined ) {

    if ( !initialRole ) return 'client';

    switch ( initialRole ) {

      case UserResponseDtoRoleEnum.Patient:
        return 'client';

      case UserResponseDtoRoleEnum.Company:
        return 'business';

      default:
        return initialRole;

    }

  }

  return (
    <>

      <Header

        role = { role }
        hasAccess = { hasAccess }
        hasBoxShadow = { hasHeaderBoxShadow }

      />

      {( isAllTherapistPage && isMobile ) && <BannersCarousel slides = { banners } />}

      <main>{children}</main>

      {( isAllTherapistPage || isTherapistPage ) && <Footer role = { footerRole( role ) } />}

    </>

  );

};

const BannersCarousel = styled(Carousel)`
  width: 100%;
  margin-top: 50px;
`;

export default BaseLayout;