import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Arrow } from '@app/ui/iconComponents';
import Container from '@app/components/Container';
import FlatBtn from '@app/ui/components/FlatBtn';
import { BreakpointsEnum } from '@app/types';
import { MainLayout } from '../MainLayout';

interface Props {
  children?: ReactNode;
}

const BackLayoutV2: FC<Props> = ({ children }) => {
  const router = useRouter();

  return (
    <MainLayout>
      <StyledContainer>
        <StyledBreadcrumbsContainer>
          <StyledFlatBtn
            onClick={() => router.back()}
            icon={<Arrow width={16} height={16} />}
          >
            Назад
          </StyledFlatBtn>
        </StyledBreadcrumbsContainer>
        {children}
      </StyledContainer>
    </MainLayout>
  );
};

export const getBackLayoutV2 = (page: ReactNode) => {
  return <BackLayoutV2>{page}</BackLayoutV2>;
};

export default BackLayoutV2;

const StyledBreadcrumbsContainer = styled.div`
  margin-bottom: 0px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-bottom: 18px;
  }
`;

const StyledFlatBtn = styled(FlatBtn)`
  position: relative;
  top: 1.29vw;
  left: 0;
  z-index: 105;
  padding: 0 5px;
  opacity: 0.7;
  transition: 0.3s;

  div {
    margin-right: 4px;
  }

  svg {
    opacity: 0.7;
    transform: rotate(180deg);
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1440px;

  @media (max-width: ${BreakpointsEnum.sm}px) {

    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;
    
  }
`;
