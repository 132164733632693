import { COLORS, TYPOGRAPHY } from '@app/constants';
import {
  cloneElement,
  ReactElement,
  AnchorHTMLAttributes,
  forwardRef,
} from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';

type StyleButton = 'primary' | 'secondary' | 'tertiary' | 'text' | 'cancel';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  styleType?: StyleButton;
  icon?: ReactElement;
  isReversed?: boolean;
  isLarge?: boolean;
  className?: string;
  isLoading?: boolean;
}

const ButtonLink = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      styleType = 'secondary',
      icon,
      isReversed = false,
      isLarge = false,
      className,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const cloneIcon =
      icon &&
      cloneElement(icon, {
        color: icon.props.color || 'currentColor',
      });

    return (
      <Root
        $type={styleType}
        $isReversed={isReversed}
        $isLarge={isLarge}
        className={className}
        ref={ref}
        {...props}
      >
        {icon && <Icon>{cloneIcon}</Icon>}
        {props.children}
        {isLoading && <StyledSpinner isLoading={isLoading} />}
      </Root>
    );
  },
);

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;

interface StyleProps {
  $type: StyleButton;
  $isReversed: boolean;
  $isLarge: boolean;
}

const Icon = styled.div`
  display: flex;

  & > svg {
    color: ${COLORS.grayscale700};
  }
`;

const Root = styled.a<StyleProps>`
  ${TYPOGRAPHY.body1Regular14}
  position: relative;
  display: inline-flex;
  flex-direction: ${({ $isReversed }) => $isReversed && 'row-reverse'};
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  padding: ${({ $isLarge }) => ($isLarge ? '12px' : '8px')} 32px;
  min-width: ${({ $isLarge }) => $isLarge && '200px'};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px ${COLORS.elementsStatesFocusedBorder};
  }

  ${Icon} {
    ${({ $isReversed }) =>
      $isReversed
        ? css`
            margin-left: 15px;
          `
        : css`
            margin-right: 15px;
          `};
  }

  ${({ $type }) => {
    switch ($type) {
      case 'primary':
        return css`
          color: ${COLORS.grayscaleWhite};
          background-color: ${COLORS.primary300};
          &:hover {
            background-color: ${COLORS.primary400};
          }
          &:active {
            background-color: ${COLORS.primary500};

            ${Icon} {
              & > svg {
                color: ${COLORS.grayscaleWhite};
              }
            }
          }
        `;
      case 'secondary':
        return css`
          color: ${COLORS.primary300};
          background-color: ${COLORS.primary50};
          &:hover {
            background-color: ${COLORS.primary100};
          }
          &:active {
            background-color: ${COLORS.primary200};
          }
        `;
      case 'cancel':
        return css`
          color: ${COLORS.systemError};
          background-color: ${COLORS.grayscale0};
          &:hover {
            background-color: ${COLORS.grayscale100};
          }
          &:active {
            background-color: ${COLORS.grayscale200};
          }
        `;
      case 'text':
        return css`
          color: ${COLORS.primary300};
          background-color: transparent;
          &:hover {
            color: ${COLORS.primary400};
          }
          &:active {
            color: ${COLORS.primary500};
          }

          ${Icon} {
            & > svg {
              color: ${COLORS.primary300};
            }
          }
        `;
      default:
        return css`
          color: ${COLORS.grayscale700};
          border: 1px solid ${COLORS.grayscale100};
          background-color: transparent;
        `;
    }
  }};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
`;
