// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { COLORS } from '@app/constants';
import { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Headroom from 'react-headroom';
import Container from '../Container';
import TypedLink from '../TypedLink';
import { useAppSelector } from '@app/store/hooks';
import { selectors } from '@app/store';
import { useLogo } from './useLogo';
import {
  UserMetadataResponseDtoTherapistStatusEnum,
  UserResponseDtoRoleEnum,
} from '@app/api';
import dynamic from 'next/dynamic';
import { getHomepagePathByRole } from '@app/helpers/getHomepagePathByRole';
import { BreakpointsEnum } from '@app/types';
import NavLink from '@app/ui/components/NavLink';
import { useHasMounted } from '@app/hooks/useHasMounted';
import { SIDE_NAVIGATION_LINKS } from '@app/constants/sideNavigationLinks';
import { isEmptyObject } from '@app/utils/isEmptyObject';
import { isEmailOrPhoneConfirmed } from '@app/helpers/isEmailOrPhoneConfirmed';
import { useRouter } from 'next/router';
import { ROUTES } from '@app/routers';
import ConnectCardBanner from '../ConnectCardBanner';
import { WhatsAppBtn } from '@app/ui/components/WhatsAppBtn';
import { useSignUpLink } from './useSignUpLink';
import SignInButton from '@app/ui/components/SignInButton';
import { useClientSize } from '@/app/hooks';
const Notifications = dynamic(import('../Notifications'));
const Menu = dynamic(() => import('../../ui/components/Menu'), { ssr: false });

interface Props {
  className?: string;
  hasAccess?: boolean;
  role?: UserResponseDtoRoleEnum;
  hasBoxShadow?: boolean;
}

const Header: FC<Props> = ({
  hasAccess,
  className,
  role,
  hasBoxShadow = true,
}) => {

  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const { pathname } = useRouter();
  const user = useAppSelector(selectors.auth.selectUser);
  const metadata = useAppSelector(selectors.auth.selectMetadata);
  metadata.integration;
  const logo = useLogo({
    role: role || user.role,
    integration: metadata.integration,
  });
  const signUpLink = useSignUpLink(role);
  const hasMounted = useHasMounted();
  const includedHomePathSet = SIDE_NAVIGATION_LINKS.client.map(
    (item) => item.to,
  );

  const [isAuthorized, setAuthorized] = useState(false);

  useEffect(() => {
    setAuthorized(!isEmptyObject(user));
  }, [user]);

  const shouldShowCardBanner =
    hasMounted &&
    hasAccess &&
    isEmailOrPhoneConfirmed(user) &&
    metadata?.therapistStatus ===
      UserMetadataResponseDtoTherapistStatusEnum.Verified &&
    user.role === UserResponseDtoRoleEnum.Therapist &&
    (!user.paymentMethodId || !metadata.isVideoUploaded);

  return (
    <StyledHeadroom>
      <Root
        className={className}
        $hasAccess={hasAccess}
        $hasBoxShadow={hasBoxShadow}
        $isAuthorized={isAuthorized}
      >
        <StyledContainer $hasAccess={hasAccess}>
          <Nav $hasAccess={hasAccess} $isAuthorized={isAuthorized}>
            <LogoContainer>
              {hasMounted && user.role ? (
                <TypedLink to='/'>
                  <a>{logo}</a>
                </TypedLink>
              ) : (
                <a
                  href={
                    pathname === ROUTES['/login/business']
                      ? 'https://pogovorim.online/business'
                      : 'https://pogovorim.online/'
                  }
                >

                  {logo}

                </a>
              )}
            </LogoContainer>

            {hasMounted && hasAccess && (
              <>
                { ( ![ 'therapist' ].includes( user.role ) && !isMobile ) && (
                  <NavList>
                    <NavListItem>
                      <StyledNavLink
                        to={getHomepagePathByRole(user.role)}
                        includedPathSet={includedHomePathSet}
                      >
                        Домашняя страница
                      </StyledNavLink>
                    </NavListItem>
                    <NavListItem>
                      <StyledNavLink to="/all-therapists">
                        Все специалисты
                      </StyledNavLink>
                    </NavListItem>
                    <NavListItem>
                      <StyledNavLink to="/child-therapists">
                        Детские психологи
                      </StyledNavLink>
                    </NavListItem>
                  </NavList>
                )}
                {isAuthorized && (
                  <>
                    <NotificationsWrapper>
                      <WhatsAppWrapper>
                        <WhatsAppBtn />
                      </WhatsAppWrapper>
                      <StyledNotifications />
                    </NotificationsWrapper>
                    <MenuContainer>
                      <Menu
                        role={user.role}
                        username={user.firstname || ''}
                        avatarUrl={user.avatarKey}
                      />
                    </MenuContainer>
                  </>
                )}
              </>
            )}

            {hasMounted && !isAuthorized && (
              <ButtonsWrapper>
                <WhatsAppBtn />
                {(pathname === ROUTES['/all-therapists'] || pathname === ROUTES['/child-therapists']) ? (
                  <SignInButton signUpLink={signUpLink} />
                ) : null}
              </ButtonsWrapper>
            )}
          </Nav>

          {hasMounted && hasAccess && (
            <>
              { ( ![ 'therapist' ].includes( user.role ) && isMobile ) && (
                <NavListMobile>
                  <NavListItem>
                    <StyledNavLink to="/all-therapists">
                      Для себя
                    </StyledNavLink>
                  </NavListItem>
                  <NavListItem>
                    <StyledNavLink to="/child-therapists">
                      Для ребенка
                    </StyledNavLink>
                  </NavListItem>
                </NavListMobile>
              )}
            </>
          )}

        </StyledContainer>
      </Root>
      {shouldShowCardBanner && (
        <ConnectCardBanner
          isRequiredCard={!user.paymentMethodId}
          isRequiredVideo={!metadata.isVideoUploaded}
        />
      )}
    </StyledHeadroom>
  );
};

export default Header;

interface StyleProps {
  $isAuthorized?: boolean;
  $hasAccess?: boolean;
  $hasBoxShadow?: boolean;
}

const StyledHeadroom = styled(Headroom)`
  position: relative;
  z-index: 100;
`;

const Root = styled.header<StyleProps>`
  position: relative;
  background-color: ${COLORS.grayscaleWhite};
  box-shadow: ${({ $hasBoxShadow }) =>
    $hasBoxShadow ? '0 8px 12px 2px rgba(94, 112, 148, 0.04)' : 'none'};
  width: 100%;
  min-height: 60px;
  height: min-content;
  display: flex;
  @media (max-width: ${BreakpointsEnum.sm}px) {
    ${({ $isAuthorized }) =>
      $isAuthorized &&
      css`
        padding-top: ${$isAuthorized ? '15px' : '0'};
        height: ${$isAuthorized ? 'min-content' : '92px'};
        display: ${$isAuthorized ? 'block' : 'flex'};
        align-items: center;
      `}
  }
`;

const StyledContainer = styled(Container)<StyleProps>`
  display: flex;
  align-items: center;
  padding-top: ${({ $hasAccess }) => !$hasAccess && '14px'};
  padding-bottom: ${({ $hasAccess }) => !$hasAccess && '14px'};
  width: 100%;
  flex-wrap: wrap;
`;

const Nav = styled.nav<StyleProps>`
  display: flex;
  width: 100%;

  ${({ $isAuthorized }) =>
    !$isAuthorized &&
    css`
      justify-content: space-between;
    `}

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-left: 0;

    ${({ $isAuthorized }) =>
      $isAuthorized &&
      css`
        display: grid;
        row-gap: 22px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Notifications Logo MenuContainer'
          'NavList NavList NavList';
      `};
  }
`;

const NavList = styled.ul`
  display: flex;
  align-items: center;
  margin-right: auto;
  grid-area: NavList;
`;

const NavListMobile = styled.ul`
  display: flex;
  margin-top: 20px;
`;

const NavListItem = styled.li`
  &:last-child {
    margin-right: 10px;
  }
  &:not(:last-child) {
    margin-right: 60px;
  }
  @media (max-width: ${BreakpointsEnum.sm}px) {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

const NotificationsWrapper = styled.div`
  margin-left: auto;
  grid-area: Notifications;
  display: flex;
  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-left: unset;
  }
`;

const StyledNotifications = styled(Notifications)`
  margin-right: 20px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-right: 0;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  grid-area: MenuContainer;
  align-items: center;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    justify-content: flex-end;
  }
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;

  &[data-current='true']:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 2px;
    background-color: ${COLORS.primary400};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding-top: 0;
    padding-bottom: 14px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  grid-area: Logo;
  margin-right: 40px;
  min-width: 260px;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    min-width: fit-content;

  }
`;

const WhatsAppWrapper = styled.div`
  margin: auto 0;
  margin-right: 16px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-right: 8px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 28px;

  margin-right: 20px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-right: 10px;
  }
`;
