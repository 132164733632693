import { COLORS } from '@app/constants';
import styled from 'styled-components';
import { FC } from 'react';
import { SideNavigationItem } from '@app/types';
import NavLink from '@app/ui/components/NavLink';
import { BreakpointsEnum } from '@app/types';

interface Props {
  data: SideNavigationItem[];
  className?: string;
}

const SideNavigation: FC<Props> = ({ data, className, ...props }) => {
  return (
    <Root className={className} {...props}>
      <NavList>
        {data.map((item, index) => (
          <ListItem key={index}>
            <StyledNavLink to={item.to} exact={item?.exact}>
              {item.title}
            </StyledNavLink>
          </ListItem>
        ))}
      </NavList>
    </Root>
  );
};

export default SideNavigation;

const Root = styled.div`
  max-width: 260px;
  width: 100%;
  background-color: ${COLORS.grayscale0};
  border-radius: 20px;
  padding: 28px 20px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    max-width: 100vw;
    background-color: ${COLORS.grayscaleWhite};
    padding: 0;
  }
`;

const NavList = styled.div`
  @media (max-width: ${BreakpointsEnum.sm}px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
`;

const ListItem = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  display: block;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    white-space: nowrap;
    padding: 10px;
    border-radius: 8px;

    &[data-current='true'] {
      background-color: ${COLORS.grayscale0};
    }
  }
`;
