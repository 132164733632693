import { UserMetadataResponseDto, UserResponseDtoRoleEnum } from '@app/api';

interface Arguments {
  userRole: UserResponseDtoRoleEnum;
  metadata: UserMetadataResponseDto;
}

export const isInterviewDone = ({ userRole, metadata }: Arguments) =>
  userRole === UserResponseDtoRoleEnum.Patient &&
  metadata.allowedRecommendedSearch;
