import { UserResponseDtoRoleEnum } from '@app/api';
import leaves from '@app/assets/images/popover-leaves.svg';
import { COLORS, TYPOGRAPHY } from '@app/constants';
import { useHasMounted } from '@app/hooks/useHasMounted';
import { selectors } from '@app/store';
import { useAppSelector } from '@app/store/hooks';
import { BreakpointsEnum } from '@app/types';
import Popover from '@app/ui/components/Popover';
import Image from 'next/image';
import { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { getFooterByUser, getTextByUser, getTitleByUser } from './helpers';
import dynamic from 'next/dynamic';
import { Step } from 'react-joyride';
import {
  getProductTourFromStorage,
  setProductTourToStorage,
} from '@app/services/storageService';
import { isEmptyObject } from '@app/utils/isEmptyObject';

const Joyride = dynamic(() => import('react-joyride'), {
  ssr: false,
});

const SelectTherapistPopover: FC = ({ ...props }) => {
  const user = useAppSelector(selectors.auth.selectUser);
  const metadata = useAppSelector(selectors.auth.selectMetadata);
  const hasMounted = useHasMounted();
  const hasProductTourCompleted = getProductTourFromStorage();

  const { footer, text, title } = useMemo(
    () => ({
      title: getTitleByUser({ user, metadata }),
      footer: getFooterByUser({ user, metadata }),
      text: getTextByUser({ user, metadata }),
    }),
    [
      user.isEmailConfirmed,
      user.phone,
      user.email,
      user.firstname,
      metadata.allowedRecommendedSearch,
      hasMounted,
    ],
  );

  const productTourSteps: Step[] = [
    {
      content: (
        <ContainerTooltip>
          <TooltipHeader>
            <Heading>Подбор специалиста</Heading>
            <Heading>1/2</Heading>
          </TooltipHeader>
          <Content>
            На платформе большой выбор специалистов. Мы можем найти того, кто
            идеально подойдёт именно Вам. Для этого пройдите опрос.
          </Content>
        </ContainerTooltip>
      ),
      locale: { next: <NextButton>Хорошо, спасибо</NextButton> },
      placement: 'right',
      target: '.first-step',
      disableBeacon: true,
    },
    {
      content: (
        <ContainerTooltip>
          <TooltipHeader>
            <Heading>Фильтры</Heading>
            <Heading>2/2</Heading>
          </TooltipHeader>
          <Content>
            Вы также можете выбрать специалиста самостоятельно, используя
            фильтры
          </Content>
        </ContainerTooltip>
      ),
      locale: {
        last: <NextButton>Хорошо, спасибо</NextButton>,
        back: undefined,
      },
      placement: 'right',
      target: '.second-step',
      disableBeacon: true,
    },
  ];

  const handleComplite = () => {
    setProductTourToStorage(true);
  };

  if (
    !hasMounted ||
    (user.role !== UserResponseDtoRoleEnum.Patient && user.role !== undefined)
  ) {
    return null;
  }

  return (
    <Root {...props}>
      <Joyride
        callback={handleComplite}
        steps={productTourSteps}
        continuous
        hideCloseButton
        run={!hasProductTourCompleted && isEmptyObject(user)}
        scrollToFirstStep={false}
        showProgress={false}
        showSkipButton={false}
        hideBackButton={true}
        styles={{
          options: {
            zIndex: 10000,
            width: 289,
          },
          buttonNext: {
            background: 'none',
            width: '100%',
            padding: 0,
          },
        }}
      />
      <ImageWrapper>
        <Image src={leaves.src} width={250} height={241} />
      </ImageWrapper>
      <Popover className="first-step">
        <Title>{title}</Title>
        <Text>{text}</Text>
        {footer}
      </Popover>
    </Root>
  );
};

export default memo(SelectTherapistPopover);

const Root = styled.div`
  position: relative;
`;

const ImageWrapper = styled.figure`
  position: absolute;
  bottom: -5%;
  right: 35%;
  z-index: -1;
  margin: 0;
  width: 250px;
  height: 241px;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    display: none;
  }
`;

const Title = styled.h3`
  ${TYPOGRAPHY.headline2Semibold16}
  color: ${COLORS.grayscaleBlack};
  margin-bottom: 8px;
`;

const Text = styled.p`
  ${TYPOGRAPHY.body1Regular14}
  color: ${COLORS.grayscale700};
  margin-bottom: 12px;
`;

const ContainerTooltip = styled.div`
  display: flex;
  margin: -20px -10px;
  flex-direction: column;
`;

const TooltipHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Content = styled.p`
  color: ${COLORS.grayscale500};
  ${TYPOGRAPHY.footnoteRegular13}
  margin-top: 8px;
  text-align: start;
`;

const Heading = styled.p`
  color: ${COLORS.grayscale700};
  ${TYPOGRAPHY.headline2Semibold16}
`;

const NextButton = styled.p`
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: none;
  color: ${COLORS.primary300};
`;
